import { Component, OnInit } from '@angular/core';
import { ToastService } from './toast.service';
import { ToastMessage } from './toast.interfaces';
import { Animations } from '../shared/animations';

@Component({
	selector: 'app-toast',
	templateUrl: './toast.component.html',
	styleUrls: ['./toast.component.scss'],
	animations: [Animations.toast],
})
export class ToastComponent implements OnInit {
	message: ToastMessage;
	counter: number;

	constructor(public toastService: ToastService) {
		this.counter = 0;
		this.message = this.toastService.message;
	}

	ngOnInit(): void {}

	close() {
		this.toastService.closeMessage();
	}
}
