import { Injectable } from '@angular/core';
import { InMemoryDbService } from 'angular-in-memory-web-api';
import { Course } from './course-detail/course.interfaces';
import houstonCourses from './houston-courses';

@Injectable({
	providedIn: 'root',
})
export class InMemoryApiService implements InMemoryDbService {
	constructor() {}
	createDb() {
		const courses: Course[] = houstonCourses;

		return { courses };
	}
}
