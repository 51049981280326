const houstonCourses = [
	{
		id: 100,
		status: false,
		name: 'Jim Mozola Memorial DGC (Bayou Course)',
		description:
			'Nulla et tellus in nulla dapibus venenatis lacinia quis enim. Integer cursus turpis pellentesque, dapibus purus at, tincidunt elit. Mauris luctus, nisl in fringilla semper, lorem magna ultricies purus, vitae pharetra est tellus ullamcorper sem. Ut tristique elementum lobortis. Aenean eleifend id nulla et feugiat. Proin eget dictum lorem. Aliquam erat volutpat. Nulla sit amet consectetur enim, id hendrerit tortor. Phasellus quis sem quis enim porta ullamcorper a et mi. Maecenas finibus fringilla sapien vitae dignissim. Duis iaculis vestibulum libero, sit amet rhoncus dui pulvinar id. Donec nulla velit, viverra fringilla ultricies eget, tempus at sapien.',
		city: 'Houston',
		state: 'Texas',
		holes: 9,
		practice_baskets: 1,
		basket_type: 'DISCatcher',
		tee: 'Grass',
		coordinates: {
			lat: 29.76253,
			lng: -95.37763,
		},
		images: [],
	},
	{
		id: 102,
		status: true,
		name: 'Mason Park',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Houston',
		state: 'Texas',
		holes: 9,
		practice_baskets: 1,
		basket_type: 'DISCatcher',
		tee: 'Grass',
		coordinates: {
			lat: 29.725551,
			lng: -95.294917,
		},
		images: [],
	},
	{
		id: 103,
		status: true,
		name: 'MacGregor Park',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Houston',
		state: 'Texas',
		holes: 21,
		practice_baskets: 1,
		basket_type: 'StrokeSavers',
		tee: 'Grass',
		coordinates: {
			lat: 29.71079,
			lng: -95.33768,
		},
		images: [],
	},
	{
		id: 104,
		status: true,
		name: 'TC Jester Park',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Houston',
		state: 'Texas',
		holes: 21,
		practice_baskets: 1,
		basket_type: 'DISCatcher',
		tee: 'Dirt',
		coordinates: {
			lat: 29.827582,
			lng: -95.455007,
		},
		images: [],
	},
	{
		id: 105,
		status: true,
		name: 'Memorial DGC',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Houston',
		state: 'Texas',
		holes: 6,
		practice_baskets: 1,
		basket_type: 'InStep',
		tee: 'Grass ',
		coordinates: {
			lat: 29.757569,
			lng: -95.517492,
		},
		images: [],
	},
	{
		id: 106,
		status: true,
		name: 'Cedar Brook Elementary School',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Houston',
		state: 'Texas',
		holes: 9,
		practice_baskets: 1,
		basket_type: 'DISCatcher',
		tee: 'Grass ',
		coordinates: {
			lat: 29.812721,
			lng: -95.502745,
		},
		images: [],
	},
	{
		id: 107,
		status: false,
		name: 'Tom Bass Regional Park - Wilmont',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Houston',
		state: 'Texas',
		holes: 18,
		practice_baskets: 1,
		basket_type: 'Mach 3',
		tee: 'Grass',
		coordinates: {
			lat: 29.588824,
			lng: -95.377357,
		},
		images: [],
	},
	{
		id: 108,
		status: false,
		name: 'Tom Bass Regional Park - Powell',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Houston',
		state: 'Texas',
		holes: 18,
		practice_baskets: 1,
		basket_type: 'StrokeSavers',
		tee: 'Grass ',
		coordinates: {
			lat: 29.59142,
			lng: -95.372019,
		},
		images: [],
	},
	{
		id: 109,
		status: true,
		name: 'Burke Crenshaw Park',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Pasadena',
		state: 'Texas',
		holes: 10,
		practice_baskets: 1,
		basket_type: 'DISCatcher',
		tee: 'Dirt ',
		coordinates: {
			lat: 29.63774,
			lng: -95.182878,
		},
		images: [],
	},
	{
		id: 110,
		status: true,
		name: 'Agnes Moffitt Park',
		description:
			'Nulla et tellus in nulla dapibus venenatis lacinia quis enim. Integer cursus turpis pellentesque, dapibus purus at, tincidunt elit. Mauris luctus, nisl in fringilla semper, lorem magna ultricies purus, vitae pharetra est tellus ullamcorper sem. Ut tristique elementum lobortis. Aenean eleifend id nulla et feugiat. Proin eget dictum lorem. Aliquam erat volutpat. Nulla sit amet consectetur enim, id hendrerit tortor. Phasellus quis sem quis enim porta ullamcorper a et mi. Maecenas finibus fringilla sapien vitae dignissim. Duis iaculis vestibulum libero, sit amet rhoncus dui pulvinar id. Donec nulla velit, viverra fringilla ultricies eget, tempus at sapien.',
		city: 'Houston',
		state: 'Texas',
		holes: 18,
		practice_baskets: 1,
		basket_type: 'DD Patriot',
		tee: 'Gravel ',
		coordinates: {
			lat: 29.812181,
			lng: -95.561346,
		},
		images: [
			'https://ang9-mapping-images.futurebutterflies.net/moffitt/01.jpg',
			'https://ang9-mapping-images.futurebutterflies.net/moffitt/02.jpg',
			'https://ang9-mapping-images.futurebutterflies.net/moffitt/03.jpg',
			'https://ang9-mapping-images.futurebutterflies.net/moffitt/01.jpg',
			'https://ang9-mapping-images.futurebutterflies.net/moffitt/02.jpg',
			'https://ang9-mapping-images.futurebutterflies.net/moffitt/03.jpg',
		],
	},
	{
		id: 111,
		status: true,
		name: 'Nottingham Park',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Houston',
		state: 'Texas',
		holes: 12,
		practice_baskets: 1,
		basket_type: 'StrokeSavers',
		tee: 'Grass ',
		coordinates: {
			lat: 29.77615,
			lng: -95.59845,
		},
		images: [],
	},
	{
		id: 112,
		status: true,
		name: 'BridgePoint Bible Church',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Katy',
		state: 'Texas',
		holes: 6,
		practice_baskets: 1,
		basket_type: 'Chainstar',
		tee: 'Natural ',
		coordinates: {
			lat: 29.78182,
			lng: -95.620666,
		},
		images: [],
	},
	{
		id: 113,
		status: true,
		name: 'Shadow Creek Ranch DGC',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Pearland',
		state: 'Texas',
		holes: 9,
		practice_baskets: 1,
		basket_type: 'DGA',
		tee: 'Natural ',
		coordinates: {
			lat: 29.559197,
			lng: -95.404569,
		},
		images: [],
	},
	{
		id: 114,
		status: true,
		name: 'McClendan Park',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Houston',
		state: 'Texas',
		holes: 9,
		practice_baskets: 1,
		basket_type: 'Mach 3',
		tee: 'Concrete ',
		coordinates: {
			lat: 29.7163,
			lng: -95.6505,
		},
		images: [],
	},
	{
		id: 115,
		status: true,
		name: 'Imperial Park',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Sugar Land',
		state: 'Texas',
		holes: 9,
		practice_baskets: 1,
		basket_type: 'DISCatcher',
		tee: 'Grass ',
		coordinates: {
			lat: 29.616133,
			lng: -95.640369,
		},
		images: [],
	},
	{
		id: 116,
		status: true,
		name: 'Wortham Trail',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Cypress',
		state: 'Texas',
		holes: 9,
		practice_baskets: 1,
		basket_type: 'DISCatcher',
		tee: 'Concrete ',
		coordinates: {
			lat: 29.92521,
			lng: -95.615945,
		},
		images: [],
	},
	{
		id: 117,
		status: true,
		name: 'Missouri City Community Park',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Missouri City',
		state: 'Texas',
		holes: 9,
		practice_baskets: 1,
		basket_type: 'Mach 3',
		tee: 'Grass ',
		coordinates: {
			lat: 29.564316,
			lng: -95.526016,
		},
		images: [],
	},
	{
		id: 118,
		status: false,
		name: 'L2 at Quail Valley',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Missouri City',
		state: 'Texas',
		holes: 0,
		practice_baskets: 1,
		basket_type: 'Chainstar',
		tee: 'Grass ',
		coordinates: {
			lat: 29.569307,
			lng: -95.551418,
		},
		images: [],
	},
	{
		id: 119,
		status: true,
		name: 'Erickson/Payne Home DGC',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Missouri City',
		state: 'Texas',
		holes: 9,
		practice_baskets: 1,
		basket_type: 'InStep',
		tee: 'Grass ',
		coordinates: {
			lat: 29.46796,
			lng: -95.485466,
		},
		images: [],
	},
	{
		id: 120,
		status: true,
		name: 'Windwood Presbyterian DGC',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Houston',
		state: 'Texas',
		holes: 18,
		practice_baskets: 1,
		basket_type: 'Mach New 2',
		tee: 'Natural ',
		coordinates: {
			lat: 30.0144,
			lng: -95.57963,
		},
		images: [],
	},
	{
		id: 121,
		status: true,
		name: 'First Colony Aquatic Center',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Sugar Land',
		state: 'Texas',
		holes: 10,
		practice_baskets: 1,
		basket_type: 'DGA',
		tee: 'Grass ',
		coordinates: {
			lat: 29.573912,
			lng: -95.584652,
		},
		images: [],
	},
	{
		id: 122,
		status: true,
		name: 'Northside Christian Church DGC',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Spring',
		state: 'Texas',
		holes: 18,
		practice_baskets: 1,
		basket_type: 'Mach 5',
		tee: 'Concrete',
		coordinates: {
			lat: 30.065481,
			lng: -95.502947,
		},
		images: [],
	},
	{
		id: 123,
		status: true,
		name: 'River Grove DGC',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Kingwood',
		state: 'Texas',
		holes: 18,
		practice_baskets: 1,
		basket_type: 'Mach 5',
		tee: 'Concrete ',
		coordinates: {
			lat: 30.027066,
			lng: -95.208576,
		},
		images: [],
	},
	{
		id: 124,
		status: true,
		name: 'Spring Valley DGC',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Spring',
		state: 'Texas',
		holes: 28,
		practice_baskets: 1,
		basket_type: 'DGA',
		tee: 'Concrete ',
		coordinates: {
			lat: 30.129187,
			lng: -95.504985,
		},
		images: [],
	},
	{
		id: 125,
		status: true,
		name: 'Crosspoint Community Church',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Katy',
		state: 'Texas',
		holes: 9,
		practice_baskets: 1,
		basket_type: 'Mach New 2',
		tee: 'Mixed ',
		coordinates: {
			lat: 29.7781,
			lng: -95.7375,
		},
		images: [],
	},
	{
		id: 126,
		status: true,
		name: 'Miramar Park',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Seabrook',
		state: 'Texas',
		holes: 9,
		practice_baskets: 1,
		basket_type: 'Mach 5',
		tee: 'Gravel ',
		coordinates: {
			lat: 29.567472,
			lng: -95.017222,
		},
		images: [],
	},
	{
		id: 127,
		status: true,
		name: 'Taylor Lake Village DGC',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Taylor Lake Village',
		state: 'Texas',
		holes: 18,
		practice_baskets: 1,
		basket_type: 'DISCatcher',
		tee: 'Grass ',
		coordinates: {
			lat: 29.575342,
			lng: -95.055991,
		},
		images: [],
	},
	{
		id: 128,
		status: true,
		name: 'Oak Meadow Park DGC',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Cypress',
		state: 'Texas',
		holes: 18,
		practice_baskets: 1,
		basket_type: 'Mach 2',
		tee: 'Concrete ',
		coordinates: {
			lat: 29.95757,
			lng: -95.719811,
		},
		images: [],
	},
	{
		id: 129,
		status: true,
		name: 'River Pointe Church DGC',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Richmond',
		state: 'Texas',
		holes: 9,
		practice_baskets: 1,
		basket_type: 'Mach 2',
		tee: 'Grass ',
		coordinates: {
			lat: 29.5622,
			lng: -95.7023,
		},
		images: [],
	},
	{
		id: 130,
		status: true,
		name: 'Pecan Grove Park',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Richmond',
		state: 'Texas',
		holes: 9,
		practice_baskets: 1,
		basket_type: 'DGA',
		tee: 'Grass ',
		coordinates: {
			lat: 29.608147,
			lng: -95.737621,
		},
		images: [],
	},
	{
		id: 131,
		status: true,
		name: 'Bear Branch Sportsfield Park',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'The Woodlands',
		state: 'Texas',
		holes: 18,
		practice_baskets: 1,
		basket_type: 'Mach 3',
		tee: 'Rubber ',
		coordinates: {
			lat: 30.191,
			lng: -95.4951,
		},
		images: [],
	},
	{
		id: 132,
		status: true,
		name: 'Stonebridge Church DGC',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'The Woodlands',
		state: 'Texas',
		holes: 18,
		practice_baskets: 1,
		basket_type: 'DISCatcher',
		tee: 'Natural ',
		coordinates: {
			lat: 30.2195,
			lng: -95.543,
		},
		images: [],
	},
	{
		id: 133,
		status: true,
		name: 'Terramont Park',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'The Woodlands',
		state: 'Texas',
		holes: 9,
		practice_baskets: 1,
		basket_type: 'Mach 5',
		tee: 'Rubber ',
		coordinates: {
			lat: 30.2031,
			lng: -95.5573,
		},
		images: [],
	},
	{
		id: 134,
		status: true,
		name: 'Gwen Hruska Park (River Plantation)',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Conroe',
		state: 'Texas',
		holes: 9,
		practice_baskets: 1,
		basket_type: 'Chainstar',
		tee: 'Grass ',
		coordinates: {
			lat: 30.245249,
			lng: -95.442808,
		},
		images: [],
	},
	{
		id: 135,
		status: true,
		name: 'Jack Brooks Park - Cedar Hills',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Hitchcock',
		state: 'Texas',
		holes: 18,
		practice_baskets: 1,
		basket_type: 'DISCatcher',
		tee: 'Dirt ',
		coordinates: {
			lat: 29.36193,
			lng: -95.04098,
		},
		images: [],
	},
	{
		id: 136,
		status: true,
		name: 'Jack Brooks Park - Gulf Meadows',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Hitchcock',
		state: 'Texas',
		holes: 18,
		practice_baskets: 1,
		basket_type: 'DISCatcher',
		tee: 'Dirt ',
		coordinates: {
			lat: 29.369147,
			lng: -95.042451,
		},
		images: [],
	},
	{
		id: 137,
		status: true,
		name: 'McDade Park',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Conroe',
		state: 'Texas',
		holes: 18,
		practice_baskets: 1,
		basket_type: 'Chainstar',
		tee: 'Concrete ',
		coordinates: {
			lat: 30.31559,
			lng: -95.51186,
		},
		images: [],
	},
	{
		id: 138,
		status: true,
		name: 'Clute Park',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Clute',
		state: 'Texas',
		holes: 9,
		practice_baskets: 1,
		basket_type: 'DISCatcher',
		tee: 'Dirt ',
		coordinates: {
			lat: 29.0249,
			lng: -95.4064,
		},
		images: [],
	},
	{
		id: 139,
		status: true,
		name: 'Dunbar Park',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Lake Jackson',
		state: 'Texas',
		holes: 18,
		practice_baskets: 1,
		basket_type: 'DISCatcher',
		tee: 'Grass ',
		coordinates: {
			lat: 29.05442,
			lng: -95.4601,
		},
		images: [],
	},
	{
		id: 140,
		status: true,
		name: 'Timber Lane Park DGC',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Spring',
		state: 'Texas',
		holes: 9,
		practice_baskets: 1,
		basket_type: 'Mach 5',
		tee: 'Grass',
		coordinates: {
			lat: 30.044216,
			lng: -95.388944,
		},
		images: [],
	},
	{
		id: 141,
		status: true,
		name: 'Texas Army Trail DGC',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Cypress',
		state: 'Texas',
		holes: 18,
		practice_baskets: 1,
		basket_type: 'Mach 5',
		tee: 'Dirt',
		coordinates: {
			lat: 29.951598,
			lng: -95.649198,
		},
		images: [],
	},
	{
		id: 142,
		status: true,
		name: 'Briscoe Park',
		description:
			'42 acre park is located at 3625 Briscoe Dr (Behind the YMCA).  The park features two lighted soccer fields, two lighted football fields, concession building with restrooms, ½ mile walking path that connects to Alvin Community College, 1.7 acre Dog Park, all-inclusive playground, three picnic pavilions with tables, fishing pond and plenty of open green space',
		city: 'Alvin',
		state: 'Texas',
		holes: 18,
		practice_baskets: 0,
		basket_type: 'DISCatcher',
		tee: 'Dirt',
		coordinates: {
			lat: 29.393049,
			lng: -95.245038,
		},
		images: [],
	},
	{
		id: 142,
		status: true,
		name: 'White Oak Bayou Bandit',
		description:
			'First tee is located by north cul-de-sac of Loren Ln. Please help pick up trash on the course.',
		city: 'Houston',
		state: 'Texas',
		holes: 18,
		practice_baskets: 2,
		basket_type: 'Mixed',
		tee: 'Dirt',
		coordinates: {
			lat: 29.873685,
			lng: -95.507837,
		},
		images: [],
	},
	{
		id: 143,
		status: true,
		name: 'Victory Disc Golf Course',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Houston',
		state: 'Texas',
		holes: 18,
		practice_baskets: 2,
		basket_type: 'DD Patriot',
		tee: 'Dirt',
		coordinates: {
			lat: 29.863855,
			lng: -95.467232,
		},
		images: [],
	},
	{
		id: 144,
		status: true,
		name: 'White Oak CC (Inwood) North',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Houston',
		state: 'Texas',
		holes: 18,
		practice_baskets: 1,
		basket_type: 'DD Patriot',
		tee: 'Dirt',
		coordinates: {
			lat: 29.8747138,
			lng: -95.4758154,
		},
		images: [],
	},
	{
		id: 145,
		status: true,
		name: 'White Oak CC (Inwood) West',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Houston',
		state: 'Texas',
		holes: 18,
		practice_baskets: 0,
		basket_type: '',
		tee: 'Dirt',
		coordinates: {
			lat: 29.873353,
			lng: -95.475208,
		},
		images: [],
	},
	{
		id: 146,
		status: true,
		name: 'Zube Park',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Hockley',
		state: 'Texas',
		holes: 18,
		practice_baskets: 1,
		basket_type: '',
		tee: 'Dirt',
		coordinates: {
			lat: 30.026949,
			lng: -95.810741,
		},
		images: [],
	},
	{
		id: 147,
		status: true,
		name: 'John Burge Park - Little Egypt',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Conroe',
		state: 'Texas',
		holes: 18,
		practice_baskets: 1,
		basket_type: 'Mach 5',
		tee: 'Concrete',
		coordinates: {
			lat: 30.383585,
			lng: -95.521647,
		},
		images: [],
	},
	{
		id: 148,
		status: true,
		name: 'Milby Park',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Houston',
		state: 'Texas',
		holes: 21,
		practice_baskets: 1,
		basket_type: 'Chainstar',
		tee: 'Mixed',
		coordinates: {
			lat: 29.705312,
			lng: -95.263796,
		},
		images: [],
	},
	{
		id: 149,
		status: false,
		name: '1776 Park',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Friendswood',
		state: 'Texas',
		holes: 9,
		practice_baskets: 1,
		basket_type: '',
		tee: 'Mixed',
		coordinates: {
			lat: 29.540635,
			lng: -95.195292,
		},
		images: [],
	},
	{
		id: 150,
		status: true,
		name: 'Evergreen Disc Golf Course',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Baytown',
		state: 'Texas',
		holes: 18,
		practice_baskets: 1,
		basket_type: 'DISCCatcher',
		tee: 'Concrete',
		coordinates: {
			lat: 29.7059809,
			lng: -94.9624317,
		},
		images: [],
	},
	{
		id: 151,
		status: true,
		name: 'Jenkins Park',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Baytown',
		state: 'Texas',
		holes: 18,
		practice_baskets: 1,
		basket_type: 'DISCCatcher',
		tee: 'Concrete',
		coordinates: {
			lat: 29.776334,
			lng: -94.938656,
		},
		images: [],
	},
	{
		id: 152,
		status: false,
		name: 'Dodson Lake Park',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Houston',
		state: 'Texas',
		holes: 9,
		practice_baskets: 1,
		basket_type: '',
		tee: '',
		coordinates: {
			lat: 29.8425053,
			lng: -95.3287679,
		},
		images: [],
	},
	{
		id: 153,
		status: true,
		name: 'Cornerstone Church',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Houston',
		state: 'Texas',
		holes: 18,
		practice_baskets: 1,
		basket_type: 'Mach 5',
		tee: 'Mixed',
		coordinates: {
			lat: 29.0524356,
			lng: -95.5325097,
		},
		images: [],
	},
	{
		id: 154,
		status: true,
		name: 'Flewellen Creek',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Fulshear',
		state: 'Texas',
		holes: 18,
		practice_baskets: 1,
		basket_type: 'Mach 5',
		tee: 'Concrete',
		coordinates: {
			lat: 29.704208,
			lng: -95.861439,
		},
		images: [],
	},
	{
		id: 155,
		status: true,
		name: 'Rice University',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris consequat, tellus non ornare elementum, nisi eros finibus velit, eget rutrum nunc libero eu felis. Pellentesque luctus commodo nisl, sit amet tempus enim condimentum ut. Nam dictum augue at sem luctus tincidunt sit amet in libero. Proin varius nec nibh vitae consectetur. Cras nec odio fringilla, maximus neque et, mattis justo. Pellentesque bibendum sit amet nisl eu porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
		city: 'Houston',
		state: 'Texas',
		holes: 9,
		practice_baskets: 1,
		basket_type: 'Mach 3',
		tee: 'Natural',
		coordinates: {
			lat: 29.714148,
			lng: -95.402193,
		},
		images: [],
	},
];

export default houstonCourses;
