<div class="toast" *ngIf="toastService.message" @toast [class.toast__success]="!toastService.message.error"
  [class.toast__error]="toastService.message.error" (click)="close()">
  <div class="toast-message">{{ toastService.message.text }}</div>
  <div class="toast-close">
    <svg class="close-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" enable-background="new 0 0 40 40">
      <circle class="circle" cx="20" cy="20" r="19" opacity="0" stroke="#000" stroke-width="2.5" stroke-linecap="round"
        stroke-miterlimit="10" fill="none"></circle>
      <path d="M20 1c10.45 0 19 8.55 19 19s-8.55 19-19 19-19-8.55-19-19 8.55-19 19-19z" class="progress" stroke="#fff"
        stroke-width="2.5" stroke-linecap="round" stroke-miterlimit="10" fill="none"></path>
      <line class="line" x1="15" y1="15" x2="25" y2="25" stroke="#fff" stroke-width="2.5" stroke-linecap="round"
        stroke-miterlimit="10">
      </line>
      <line class="line" x1="25" y1="15" x2="15" y2="25" stroke="#fff" stroke-width="2.5" stroke-linecap="round"
        stroke-miterlimit="10">
      </line>
    </svg>
  </div>
</div>
