import { Injectable } from '@angular/core';
import { ToastMessage } from './toast.interfaces';

@Injectable({
	providedIn: 'root',
})
export class ToastService {
	message: ToastMessage;
	messageQueue: ToastMessage[];
	messageTimeout: any;

	constructor() {
		this.messageQueue = [];
	}
	addMessage(message: ToastMessage) {
		if (!message.displayTime) {
			message.displayTime = 5000;
		}
		if (this.message) {
			this.messageQueue.push(message);
		} else {
			this.message = message;
			// clear the current message after 3 seconds
			this.messageTimeout = setTimeout(() => {
				this.closeMessage();
			}, message.displayTime);
		}
	}

	// close current message and load the next one
	closeMessage() {
		this.message = null;
		clearTimeout(this.messageTimeout);
		this.loadNextMessage();
	}

	// will load the next message after a 500 milisecond delay
	// this delay gives the previous message time to animate out
	private loadNextMessage() {
		if (this.messageQueue.length > 0) {
			setTimeout(() => {
				this.addMessage(this.messageQueue.shift());
			}, 500);
		}
	}
}
