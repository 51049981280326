import { animate, style, transition, state, trigger, stagger, query } from '@angular/animations';

export let Animations = {
	fadeInOut: trigger('fadeInOut', [
		state('void', style({ opacity: 0 })),
		transition(':enter', [
			style({ opacity: '0' }),
			animate('.15s 150ms', style({ opacity: '1' })),
		]),
		transition(':leave', [style({ opacity: '1' }), animate('.15s', style({ opacity: '0' }))]),
	]),
	delayfadeInOut: trigger('delayfadeInOut', [
		state('void', style({ opacity: 0 })),
		transition(':enter', [
			style({ opacity: '0' }),
			animate('1s 150ms', style({ opacity: '1' })),
		]),
		transition(':leave', [style({ opacity: '1' }), animate('1s', style({ opacity: '0' }))]),
	]),
	listAnimation: trigger('listAnimation', [
		transition('* <=> *', [
			query(
				':enter',
				[
					style({ opacity: 0, right: '-15px' }),
					stagger('30ms', animate('200ms ease-out', style({ opacity: 1, right: 0 }))),
				],
				{ optional: true }
			),
			query(':leave', animate('50ms ease-in', style({ right: '-120px' })), {
				optional: true,
			}),
		]),
	]),
	toast: trigger('toast', [
		transition(':enter', [
			style({ bottom: '-40px' }),
			animate('.3s ease-out', style({ bottom: '30px' })),
		]),
		transition(':leave', [
			style({ bottom: '30px' }),
			animate('.2s', style({ bottom: '-40px' })),
		]),
	]),
	slideInLeft: trigger('slideInLeft', [
		transition(':enter', [
			style({ right: '-250px' }),
			animate('.8s ease-out', style({ right: '0' })),
		]),
		transition(':leave', [animate('.3s ease', style({ right: '-250px' }))]),
	]),
	slideDown: trigger('slideDown', [
		transition(':enter', [
			style({ top: '-130px' }),
			animate('.8s ease-out', style({ top: '0' })),
		]),
		transition(':leave', [animate('.3s ease', style({ top: '-130px' }))]),
	]),
	nameSlideInLeft: trigger('nameSlideInLeft', [
		transition(':enter', [
			style({ width: '0' }),
			animate('.4s ease-out', style({ width: '100%' })),
		]),
		transition(':leave', [animate('.2s ease', style({ width: '0' }))]),
	]),
};
